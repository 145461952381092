import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Sections from '../components/Sections'
import Linked from "../components/Linked"

const NotFoundPage = () => {
  
  const navLinks = [{ name: "Home", linkTo: `` }]

  return (
  
  <Layout navLinks={navLinks} homeLink={{name: "404", linkTo: "/404#404"}} >
    <SEO title="Oops nothing there" />
    <Sections.Section id="404" className="flex flex-col text-center justify-center px-8" >
      <h1>¯\_(ツ)_/¯</h1>
    <h2 className="my-4">Oops appears that page doesn't exist.</h2>
    <p>Why don't we head back <Linked className="text-accent hover:text-dark-accent hover:underline" linkTo="/">home</Linked>  and start again...</p>
    </Sections.Section>
  </Layout>
)}

export default NotFoundPage
